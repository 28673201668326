import { createContext, useState, useRef } from "react"
import Login from "../components/Login"
import useResize from "../hooks/useResize"

let LoginContext;
let { Provider } = (LoginContext = createContext());

let LoginProvider = ({ children }) => {
  const [loginOpen, setLoginOpen] = useState(false)
  const { width } = useResize()
  const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
  let top = useRef(null);

  function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation()
  }

  const openLogin = () => {
    if(width <= 768) {
      document.body.style.position = 'fixed'
      // document.body.style.height = '0'
    }
    top.current = window.scrollY
    setLoginOpen(true)
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'
    document.body.style.paddingRight = `${scrollBarCompensation}px`
    document.body.addEventListener('touchmove', preventDefault)
    document.addEventListener('touchmove', preventDefault)
  }

  const closeLogin = () => {
    setLoginOpen(false)
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
    document.body.style.paddingRight = ''
    document.body.removeEventListener('touchmove', preventDefault)
    document.removeEventListener('touchmove', preventDefault)
    window.scrollTo(0, top.current)

    if(width <= 768) {
      document.body.style.position = ''
      // document.body.style.height = '100vh'
    }
  }

  return (
    <Provider value={{ loginOpen, closeLogin, openLogin }}>
      <Login open={loginOpen} close={closeLogin}/>
      {children}
    </Provider>
  );
};

export { LoginContext, LoginProvider };
