import {useState, useEffect} from "react"
import { DateTime } from "luxon"

import Modal from "../Modal"
import close from "../../assets/icons/closeLogin.svg"
import check from "../../assets/icons/check.svg"
import userInput from "../../assets/icons/userInput.svg"
import emailInput from "../../assets/icons/emailInput.svg"
import pc from "../../assets/images/pc.png"
import Input from "../Input"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const Login = ({open, close: handleClose}) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(false)
  const [sended, setSended] = useState(false)

  const { width } = useResize()

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [sended])

  const postDataToSheets = async (data) => {
    try {
      await fetch('https://sheet.best/api/sheets/3cb4a1dc-f4be-4457-b978-060019e8c924', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getDatas = () => {
    const res = fetch("https://ipapi.co/json").then(res => res.json())
    if(res){
      return res
    }
    const res1 = fetch("https://hutils.loxal.net/whois").then(res => res.json())
    return res1
  }

  const handleSetEmail = (e) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
    setEmail(e.target.value)
    setEmailValid(regex.test(e.target.value))
  }

  const handleSubmit = () => {
    const date = DateTime.now().setZone("UTC+2").toFormat("dd/LL/yyyy HH:mm:ss")

    getDatas().then(res => {
      postDataToSheets({
        name,
        email,
        date,
        ip: res?.ip,
        city: res?.city,
        stateprov: res?.region || "-",
        country_name: res?.country_name || res?.country,
      })
    })

    setSended(true)
  }

  const onClose = () => {
    setName("")
    setEmail("")
    setEmailValid(false)
    handleClose()
  }

  return (
    <Modal open={open} close={onClose}>
      <div className={classes.containerWithImage}>
        <div className={classes.container}>
          <div className={classes.topBlock}>
            <div className={classes.header}>
              <div className={classes.leftBlock}>
                <img src="/images/logo.svg" alt="logo"/>
                <span>firbo.io</span>
              </div>

              <img src={close} alt={close} onClick={onClose}/>
            </div>

            {sended ? <h2 className={`${classes.name} ${classes.nameSended}`}>firbo.io is coming soon!</h2> :
              <h2 className={classes.name}>Unique insights into
              <br/>
              <span>developer productivity</span>
            </h2>}

            {sended && <span className={classes.text}>You will get additional notification <br/> on email you’ve entered earlier</span>}

            {!sended && <div className={classes.inputsBlock}>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className={classes.nameInput}
                icon={userInput}
              />

              <Input
                value={email}
                onChange={handleSetEmail}
                placeholder="Work email"
                type={email}
                className={classes.emailInput}
                icon={emailInput}
              />
            </div>}
          </div>

          {sended ?
            <button
              className={classes.buttonSended}
              onClick={() => null}
            >
              <img src={check} alt="check"/>
              Signed up successfully
            </button> :
            <button
              className={classes.button}
              disabled={!emailValid || !name}
              onClick={handleSubmit}
            >
              Sign up
            </button>
          }
        </div>

        {width <= 768 && <img src={pc} alt="pc" className={classes.pc}/>}
      </div>
    </Modal>
  )
}

export default Login
