import React from 'react'
import ReactDOM from 'react-dom/client'

import {LoginProvider} from "./context/loginContext"
import App from './App'
import "./assets/styles/index.scss"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoginProvider>
      <App />
    </LoginProvider>
  </React.StrictMode>
);

