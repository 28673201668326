import classes from "./index.module.scss"

const Input = ({value, onChange, icon, type = "text", placeholder = "", className}) => {
  const styles=`${className ? className : ""} ${classes.input}`

  return (
    <div className={classes.container}>
      {icon && <img src={icon} alt="person" className={classes.image}/>}
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles}
        type={type}
      />
    </div>
  )
}

export default Input
