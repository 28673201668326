import {Audio} from "react-loader-spinner"
import classes from "./index.module.scss"

import Modal from "../Modal"

const Loader = ({open}) => {
  return(
  <div className={classes.modal}>
    <Audio
      height="100"
      width="100"
      color="#82C43C"
      ariaLabel="audio-loading"
      visible={true}
    />
  </div>
  )
}

export default Loader
