import Popup from "reactjs-popup";
import "./modal.scss"

const Modal = ({ open, close, children }) => {

  return (<Popup
    open={open}
    closeOnEscape
    closeOnDocumentClick
    lockScroll
    position="center"
    onClose={close}
    id="modal"
    preventScroll={false}
  >
    {children}
  </Popup>)
}

export default Modal
